import { createRouter,createWebHistory } from 'vue-router'

import Index from '@/pages/Index'
import About from '@/pages/about'
import Blog from '@/pages/blog'
import i18n from '@/i18n/i18n'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      meta: {
        title: i18n.global.tm('homePage.title'),
        keyword: i18n.global.tm('homePage.keywords'),
        description:i18n.global.tm('homePage.description')
      }
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        title: i18n.global.tm('aboutPage.title'),
        keyword: i18n.global.tm('aboutPage.keywords'),
        description:i18n.global.tm('aboutPage.description')
      }
    },
    {
      path: '/blog/',
      name: 'Blog',
      component: Blog,
      meta: {
        title: i18n.global.tm('aboutPage.title'),
        keyword: i18n.global.tm('aboutPage.keywords'),
        description:i18n.global.tm('aboutPage.description')
      }
    }
  ]
 })

 export default router

