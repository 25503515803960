<template>
    <footer class="footer purple relative pt-100" id="footer">
            <canvas id="footer-dots"></canvas>
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="section-heading text-center">
                            <h6 class="sub-title text-uppercase wow fadeInUp"></h6>
                            <h2 class="title wow fadeInUp">{{$t('home.contact.title')}}</h2>
                            <p class="wow fadeInUp">{{ $t('home.contact.info') }}</p>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <ul class="contact-link wow fadeInUp">
                            <li>
                                <span class="contact-icon"><img src="@/assets/images/mail.png" alt="Mail Icon"></span>
                                <a href="mailto:admin@zklion.com "> admin@zklion.com </a>
                            </li>
                            <!-- <li>
                            <span class="contact-icon"><img src="@/assets/images/phone.png" alt="Phone Icon"></span>
                            <a href="tel:+681234567890">(+68) </a>
                            </li> -->
                            <li>
                                <span class="contact-icon"><img src="@/assets/images/telegram.png" alt="Telegram Icon"></span>
                                <a href="https://t.me/zklion">{{ $t('home.contact.telegram') }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <!-- <form method="post" class="get-touch contactfrm wow fadeInUp"> -->
                        <div  class="get-touch contactfrm wow fadeInUp" >
                        <div v-if="isError" class="contactfrmmsg red">{{ retContent }}</div>
                        <div v-else class="contactfrmmsg">{{ retContent }}</div>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" v-model="formData.name" name="text" class="form-control" :placeholder="$t('home.contact.name')" required="">
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" v-model="formData.email" name="text" class="form-control" :placeholder="$t('home.contact.email')" required="">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" v-model="formData.message"  :placeholder="$t('home.contact.message')"></textarea>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 text-center">
                                    <button @click="save_Message" class="button">{{ $t('btn.sendMessage') }}</button>
                                </div>
                            </div>
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
            <div class="copyright mt-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-9 order-r-2">
                            <p>&#9400;{{ $t('footer.title') }} <a href="https://zklion.com/" >zklion.com</a></p>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-3 order-r-1">
                            <ul>
                                <!-- <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href="https://twitter.com/zklion_hk"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>

                                <li><a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a></li> -->
                                <li>
                                    <a class="float-item"  href="https://t.me/zklion" target="_blank">
                                    <img src="@/assets/images/telegram-black.png" width="30px;" height="30px">
                                    </a>
                                </li>
                                    <li><a class="float-item"  href="https://twitter.com/ZKLion_hk" target="_blank">
                                    <img src="@/assets/images/twitter.png" width="30px;" height="30px">
                                </a></li>
                                <!-- <a class="float-item"><img src="@/assets/images/discord.png" width="40px;" height="40px"></a> -->
                                    <li><a class="float-item"><img src="@/assets/images/Github.png" width="30px;" height="30px"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        <div class="floating-box">
             <a class="float-item"  href="https://t.me/zklion" target="_blank">
                <img src="@/assets/images/telegram-black.png" width="40px;" height="40px">
            </a>
            <a class="float-item"  href="https://twitter.com/ZKLion_hk" target="_blank">
                <img src="@/assets/images/twitter.png" width="40px;" height="40px">
            </a>
            <!-- <a class="float-item"><img src="@/assets/images/discord.png" width="40px;" height="40px"></a> -->
            <a class="float-item"><img src="@/assets/images/Github.png" width="40px;" height="40px"></a>
            <!-- <a class="float-item" href="https://medium.com/@marketing_47582" target="_blank"><img src="@/assets/images/tg.png" width="40px;" height="40px"></a> -->
            <a class="float-item"  @click="scrollToTop"><img src="@/assets/images/backTop.png" width="40px;" height="40px"></a>
        </div>
            
    </footer>
</template>
<script>
import { saveMessage } from '../api/common';
import { validatorEmail } from '@/utils/validate.js'
export default {
    name: 'Footer',
    data() {
        return {
            retContent: 'Thank You! Your message has been sent.',
            isError: false,
            isShow:false,
            formData: {
                name: '',
                email: '',
                message: '',
                timestamp: Math.round(new Date().getTime() / 1000)
            }
        }
    },
    mounted() { 
        if ($("#footer-dots").length > 0) {
            var circles = [],
                canvas = document.getElementById("footer-dots"),
                context = canvas.getContext("2d"),
                colors = ['rgba(225, 225, 225, 0.3)'],
                minSize = 2,                                        // the minimum size of the circles in px
                maxSize = 5,                                       // the maximum size of the circles in px
                numCircles = 100,                                   // the number of circles
                minSpeed = -2,                                     // the minimum speed, recommended: -maxspeed
                maxSpeed = 1,                                    // the maximum speed of the circles
                expandState = true;                                      // the direction of expansion
            function buildArray() {
                'use strict';
                for (var i = 0; i < numCircles; i++) {
                    var color = Math.floor(Math.random() * (colors.length - 1 + 1)) + 1,
                        left = Math.floor(Math.random() * (canvas.width - 0 + 1)) + 0,
                        top = Math.floor(Math.random() * (canvas.height - 0 + 1)) + 0,
                        size = Math.floor(Math.random() * (maxSize - minSize)) + minSize,
                        leftSpeed = (Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed) / 10,
                        topSpeed = (Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed) / 10,
                        expandState = expandState;

                    while (leftSpeed == 0 || topSpeed == 0) {
                        leftSpeed = (Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed) / 10,
                            topSpeed = (Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed) / 10;
                    }
                    var circle = { color: color, left: left, top: top, size: size, leftSpeed: leftSpeed, topSpeed: topSpeed, expandState: expandState };
                    circles.push(circle);
                }
            }

            function build() {
                'use strict';

                for (var h = 0; h < circles.length; h++) {
                    var curCircle = circles[h];
                    context.fillStyle = colors[curCircle.color - 1];
                    context.beginPath();
                    if (curCircle.left > canvas.width + curCircle.size) {
                        curCircle.left = 0 - curCircle.size;
                        context.arc(curCircle.left, curCircle.top, curCircle.size, 0, 2 * Math.PI, false);
                    } else if (curCircle.left < 0 - curCircle.size) {
                        curCircle.left = canvas.width + curCircle.size;
                        context.arc(curCircle.left, curCircle.top, curCircle.size, 0, 2 * Math.PI, false);
                    } else {
                        curCircle.left = curCircle.left + curCircle.leftSpeed;
                        context.arc(curCircle.left, curCircle.top, curCircle.size, 0, 2 * Math.PI, false);
                    }

                    if (curCircle.top > canvas.height + curCircle.size) {
                        curCircle.top = 0 - curCircle.size;
                        context.arc(curCircle.left, curCircle.top, curCircle.size, 0, 2 * Math.PI, false);

                    } else if (curCircle.top < 0 - curCircle.size) {
                        curCircle.top = canvas.height + curCircle.size;
                        context.arc(curCircle.left, curCircle.top, curCircle.size, 0, 2 * Math.PI, false);
                    } else {
                        curCircle.top = curCircle.top + curCircle.topSpeed;
                        if (curCircle.size != maxSize && curCircle.size != minSize && curCircle.expandState == false) {
                            curCircle.size = curCircle.size - 0.1;
                        }
                        else if (curCircle.size != maxSize && curCircle.size != minSize && curCircle.expandState == true) {
                            curCircle.size = curCircle.size + 0.1;
                        }
                        else if (curCircle.size == maxSize && curCircle.expandState == true) {
                            curCircle.expandState = false;
                            curCircle.size = curCircle.size - 0.1;
                        }
                        else if (curCircle.size == minSize && curCircle.expandState == false) {
                            curCircle.expandState = true;
                            curCircle.size = curCircle.size + 0.1;
                        }
                        context.arc(curCircle.left, curCircle.top, curCircle.size, 0, 2 * Math.PI, false);
                    }

                    context.closePath();
                    context.fill();
                    context.ellipse;
                }
            }


            var xVal = 0;

            window.requestAnimFrame = (function (callback) {
                'use strict';
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    window.oRequestAnimationFrame ||
                    window.msRequestAnimationFrame ||
                    function (callback) {
                        window.setTimeout(callback, 1000 / 60);
                    };
            })();

            function animate() {
                'use strict';
                var canvas = document.getElementById("footer-dots"),
                    context = canvas.getContext("2d");

                // clear the canvas
                context.clearRect(0, 0, canvas.width, canvas.height);


                // draw the next frame
                xVal++;
                build();

                //console.log("Prep: animate ==> requestAnimFrame");
                // request a new frame
                requestAnimFrame(function () {
                    animate();
                });
            }
            window.onload = function () {
                'use strict';
                canvas.width = document.getElementById('footer').clientWidth;
                canvas.height = document.getElementById('footer').clientHeight;
                buildArray();
                animate();
            };

            window.onresize = function () {
                'use strict';
                console.log("resize");
                canvas.width = document.getElementById('footer').clientWidth;
                canvas.height = document.getElementById('footer').clientHeight;
                //buildArray();
                animate();
            };
        }
    },
    methods: {
        save_Message() {
            $('.contactfrmmsg').slideDown('slow');
            let $this = this;
            if ($this.formData.name == '') { 
                $this.isError = true;
                $this.retContent="Your name cannot be empty"
                return false;
            }
            // 验证邮箱
            if (validatorEmail($this.formData.email) != '') { 
                $this.isError = true;
                $this.retContent = "Incorrect email format"
                return false;
            }
            if ($this.formData.message == '') {  
                $this.isError = true;
                $this.retContent = "Your Message cannot be empty"
                return false;
            }
            saveMessage($this.formData).then(res => {
                if (res.code == '200') {
                    $this.isError = false;
                    $this.retContent = 'Thank You! Your message has been sent.'
                } else { 
                    $this.isError = true;
                    $this.retContent = res.message
                }
            })
        },
         // 滚动到页面顶部
        scrollToTop() {
            console.log("滚动到页面顶部")
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动效果
            });
        }
    }
}
</script>

<style>
.contactfrm{
    max-width: 720px; 
    margin:0 auto;
    padding-top: 70px;
}
/* 自定义样式 */
.floating-box {
    position: fixed;
    padding: 10px;
    border-radius: 5px;
    z-index: 9999;
    flex-direction: column;
    bottom: 268px;
    right: 40px;
    z-index: 999;
}
.float-item{
    margin: 45px 0px 0px;
    display: block;
}
.float-item img{
    width: 40px;
    height: 40px;
    box-shadow: 0 0 6px 6px rgba(24,8,85,.1);
}
.mobile .floating-box{
    display: none;
}

</style>


