import langs from "."
import site_zh from "./module/site_zh"
const zh = {
    header: {
        switchLanguage: "切換語言",
        language: {
            zh: "zh",
            zhName: "中文",
            en: "en",
            enName: "English"
        },
        menu: {
            home: "首頁",
            pool: "證明者",
            about: "關於我們",
        },
    },
    footer: {
        "title":"2022 ZKLion 保留所有权利"
    },
    home: {
        banner: {
            title1: '最值得信赖的',
            title2: '零知识证明',
            title3:'挖掘提供商',
            info: '零知識證明計算以及Zk-snarks算法優化',
            
        },
        cryptoriusFeature: {
            label: "",
            title: '已支持的網絡',
            item1: {
                title: 'Aleo',
                info:'領先的Aleo挖礦解決方案提供商'
            },
            item2: {
                title: 'Filecoin',
                info:'領先的Filecoin挖礦提供商'
            },
            item3: {
                title: 'Scroll',
                info:'Scroll是以太坊的一個以安全為重點的擴展解決方案'
            } 
        },
        advantage: { 
            label: '優勢',
            title: '為什麽選擇我們',
            item1: {
                title: '高度優化的挖礦軟件',
                info:'對零知識證明計算和Zk-snarks算法進行了優化'
            },
            item2: {
                title: '全棧托管服務',
                info:'7*24*365高標準挖礦服務器運維'
            },
            item3: {
                title: 'ASIC',
                info:'用於zkp的高性能低功耗ASIC計算機芯片'
            },
            item4: {
                title: '監控系統',
                info:'自主研發的監控分析系統，集數據采集、可視化、監控報警、數據分析於一體'
            }  
        },
        news: {
            label: '新聞',
            title: '我們的博客',
            info: "我們提供一系列全棧服務，包括ZKP算法加速、SaaS解決方案、網絡節點運維、數據監控分析等服務",
            item0: {
                title: 'Aleo項目進展與社區更新概覽：代幣獎勵在首年有重大調整',
                date: '2024年4月',
                auther:'ZKLion.com',
                info:"近期，圍繞Aleo的幾項重要進展，特別是其簡潔工作証明（Proof of Succinct Work, PoSW）機製在首個年度展現出的優勢，以及其代幣經濟模型、核心協議更新、以及zkSnark驗証者委托機製的優化，都彰顯了Aleo在隱私計算賽道的領先地位。"
            },
            item1: {
                title: '零知识对机制设计的好处：增加信任、可信度和透明度',
                date: '2023年9月',
                auther:'Aleo.org',
                info:"自從十多年前軟件開始吞噬世界以來，可用信息的規模從未如此之大。因此，開發人員現在有機會使用可驗證計算來證明很多事情，為世界上幾乎所有的交互和軟件創建簡潔的證明。然而，由於可能性如此之大，我們需要專註於最實用的應用程序，在這些應用程序中，隱私可以產生最大的影響。"
            },
            item2: {
                title: '雲存儲服務：理解去中心化存儲方法',
                date: '2023年1月',
                auther:'filecoin.io',
                info:'在這篇文章中，我們來看看去中心化的雲存儲方法是如何為行業樹立新標準的。我們研究了為什麽需要去中心化雲存儲，它是如何工作的，以及它的定義特征。'
            },
            item3: {
                title: 'Aleo的零知識基元：打造私有Web3景觀',
                date: '2023年9月',
                auther:'Medium.com',
                info:'Aleo是一個開創性的項目，專註於在Web3生態系統中提高隱私和安全性。它利用尖端的零知識密碼學來創建完全私有和可擴展的去中心化應用程序（dApp）。'
            },
            item4: {
                title: "宣布「Scroll」倡議",
                date: '2023年7月',
                auther:'scroll.io ',
                info:'在Scroll，我們重視並感謝那些為我們的生態系統做出貢獻的人所做的努力。感謝我們在GitPOAP的朋友，我們能夠表彰和獎勵貢獻者。'
            } 
        },
        contact: {
            label: "",
            title: "聯系我们",
            telegram: '通過Telegram加入我們',
            name: "您的名字*",
            email: "您的電子郵件*",
            message:"您的信息*",
            info:"我們將積極回復，請註意查看電子郵件。"
        }
    },
    about: {
        title: '關於我們',
        info:'ZKLion是一家專註於區塊鏈、Aleo和GameFI等新興技術領域的企業。致力於為企業和個人提供高效、安全、值得信賴的解決方案和服務。通過區塊鏈技術，ZKLion為客戶創建了一個去中心化的數字生態系統，實現了信息透明、可追溯、不可篡改。在Aleo領域，ZKLion利用隱私保護和加密技術為用戶提供去中心化應用程序的隱私安全保障。同時，ZKLion還致力於GameFI領域的研發，將區塊鏈與遊戲相結合，促進遊戲經濟的發展，保護用戶權益。作為行業領導者，ZKLion不斷創新，為推動區塊鏈技術的發展和應用探索新路徑。'
    },
    btn: {
        startMining: "開始挖礦",
        comingSoon: '即將開始',
        readMore: '更多',
        sendMessage:'發送消息'
    },
    ...site_zh
}

export default zh