import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: localStorage.lang || 'zh',
  },
  getters: {
  },
  mutations: {
    switchLang(state, lang) {
      state.lang = lang
      localStorage.setItem('lang', lang)
    },
  },
  actions: {
  },
  modules: {
  }
})
