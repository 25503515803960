import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: '', // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 60000,// request timeout
})


// response interceptor
service.interceptors.response.use(
  response => {
    const {
      config = {}, data: res = {}
    } = response
    const {
      code,
      status,
      message = null,
      msg
    } = res
    const {
      headers = {}
    } = config
    const {
      returnAll = false
    } = headers
    const result = (code || code === 0) ? code : status
    if (returnAll) {
      return res
    }
    if (result !== 200 && result !== 0) {
      alert(res.message)
      // Message({
      //   message: message || msg || 'error',
      //   showClose: true,
      //   type: 'error'
      // })
      return Promise.reject(res.message || msg || 'error')
    } else {
      const {
        data = {}, token = null
      } = res
      let dataSource = null
      if (token) {
        dataSource = {
          token,
          data
        }
      } else {
        dataSource = data
      }
      return dataSource
    }
  },
  error => {
    console.log(error);
    console.log(error.response) // for debug

    // const newErr = checkStatus(error.response)
    // const {
    //   status = false
    // } = newErr
    // if (newErr.data && newErr.data.status === 1) {
    //   return newErr.data
    // } else if (status) {
    //   return {}
    // } else {
    //   return Promise.reject(newErr)
    // }
  }
)

export default service
