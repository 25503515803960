const site_en = {
    homePage: {
        title: 'ZKLion | Leading ZKP technology solutions and infrastructure service provider',
        keywords: 'ZKLion, ZKP, Zero-knowledge Proof technology, Blockchain technology, Blockchain applications, smart contracts, privacy computing, decentralized services, power services, network node optimization',
        description: 'ZKLion is a leading provider of zero-knowledge proof technology solutions and infrastructure services, offering full-stack services including ZKP algorithm acceleration, SaaS solution，network node operations, data monitoring and analytics, and is dedicated to advancing the development of smart contracts, privacy computing and decentralized ZK proof layers.'
    },
    aboutPage: {
        title: 'About Us | ZKLion | Leading ZKP technology solutions and infrastructure service provider',
        keywords: 'ZKLion, ZKP, Zero-knowledge Proof technology, Blockchain technology, Blockchain applications, smart contracts, privacy computing, decentralized services, power services, network node optimization',
        description: 'ZKLion is a leading provider of zero-knowledge proof technology solutions and infrastructure services, offering full-stack services including ZKP algorithm acceleration, SaaS solution，network node operations, data monitoring and analytics, and is dedicated to advancing the development of smart contracts, privacy computing and decentralized ZK proof layers.'
    }
}

export default site_en;