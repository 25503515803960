<template>
    <!-- <p>
        zklion is an enterprise focused on emerging technology fields such as blockchain, Aleo, and GameFI. Committed to providing efficient, safe, and trustworthy solutions and services for enterprises and individuals. Through blockchain technology, zklion has created a decentralized digital ecosystem for customers, achieving information transparency, traceability, and non tampering. In the Aleo field, zklion utilizes privacy protection and encryption technology to provide users with privacy security guarantees for decentralized applications. At the same time, zklion is also committed to research and development in the GameFI field, integrating blockchain with gaming, promoting the development of the gaming economy and protecting user rights. As a leader in the industry, zklion continues to innovate and explore new paths for promoting the development and application of blockchain technology.
    </p> -->
          <main class="main">
            <!-- <section class="page-banner"  style=" margin-top: 80px;padding-bottom: 5px;">
                <div class="container" style="background: blue;">
                    <div class="row"><div class="col-xl-12 col-lg-12 col-md-12">
                        <a>首页</a> >
                        <a>博客</a> >
                        <a>内容详情</a>
                    </div></div>
                </div>
            </section> -->
                <!-- <section class="page-banner text-center relative" style="display: none;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <h1 class="page-title wow fadeInUp">About us</h1>
                                <ul class="breadcum wow fadeInUp">
                                    <li><a href="index.html">Home</a></li>
                                    <li>Pages</li>
                                    <li>About us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> -->
                
                <section class="our-mission works purple ptb-100 relative overflow-h">
                    <div class="container" style="padding: 20px; background-color:rgba(0, 0, 0, .2);">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 text-left">
                                <a href="/">首页</a> >
                                <a>博客</a> >
                                <a>内容详情</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="container">
                        <div class="row flex-center">
                            <!-- <div class="col-xl-6 col-lg-6 col-md-12 text-center">
                                <div class="mission-video wow fadeInLeft">
                                    <img src="@/assets/images/work-img.png" alt="Mission">
                                </div>
                            </div> -->
                            <div class="col-xl-12 col-lg-12 col-md-12 wow ">
                                <div class="mission-content fadeInLeft">
                                    <h3 class="mission-title text-center"  style="padding-top: 45px;line-height: 55px;
                                    padding-bottom: 45px;">Aleo項目進展與社區更新概覽：代幣獎勵在首年有重大調整</h3>
                                    <p>在加密貨幣領域，Aleo正以其獨特的隱私保護技術和不斷完善的網路基礎設施，引起廣泛的關注。近幾個月，圍繞Aleo的幾項重要進展，特別是其簡潔工作證明（Proof of Succinct Work - PoSW）機制在首個年度做出重大的調整，其代幣經濟模型、核心協定更新、以及zkSnark驗證者委託機制的優化，都彰顯了Aleo在隱私計算賽道的領先地位。</p>
                                    <p> 
                                        <h6 style="margin-bottom: 10px;">Aleo PoSW首年釋放量調整</h6>
                                    Aleo通過調整其共識機制，特別是在PoSW上的策略性側重，有效提高了網路的產出效率。在第一年內，PoSW機制相較于傳統的權益證明（PoS）展現出了顯著的增長勢頭。這一調整不僅增強了網路的抗壓性和穩定性，還促進了代幣經濟的健康發展。具體來說，Aleo團隊對PoS和PoSW的獎勵係數進行了調整，以PoSW的獎勵係數從1/2提升至2/3，而PoS的係數則相應減少，這一變動促使PoSW在Aleo網路中的角色更為重要，為早期用戶提供更多激勵，同時也反映了Aleo對平衡網路增長與安全性之間關係的重視。</p>
                                                                    
                                    <p>
                                        <h6 style="margin-bottom: 10px;">Aleo代幣經濟模型更新</h6>
                                        伴隨技術進展，Aleo的代幣經濟學也在不斷完善。代幣經濟模型的更新，特別是引入ARC20標準提案，旨在為Aleo生態系統內的資產發行、交易和管理提供標準化框架。這不僅有利於簡化智慧合約的開發和部署，還能促進鏈上資產的互通性，為開發者和用戶帶來更便捷、高效的體驗。ARC20標準的提出，預示著Aleo在代幣化應用和服務方面正朝著更加成熟和標準化的方向邁進。
                                    </p>

                                    <p>
                                        <h6 style="margin-bottom: 10px;">關鍵協議ARC-0037與ARC-0038的實施</h6>
                                        在核心協議方面，Aleo團隊已經完成了對ARC-0037與ARC-0038的審計，這兩個提案著重於提升驗證者安全性及質押者補償的公平性。ARC-0037通過為驗證者的保證金提供獨立的提取地址，減少了潛在的安全風險，而ARC-0038則定義了合理的質押傭金率，確保委託利益相關者獲得合理回報。這兩個提案的集成與測試，標誌著Aleo在共識機制和經濟模型上的重大升級，為即將到來的主網上線奠定了堅實的基礎。
                                    </p>
                                    <p>
                                        <h6 style="margin-bottom: 10px;">zkSnark驗證者委託機制的進展</h6>
                                        Aleo在zkSnark驗證者社區的建設上也邁出了重要步伐，通過“zkSnark驗證者的委託”機制，增強了網路的去中心化和可擴展性。這一機制允許用戶將他們的驗證工作委託給更專業的驗證者，而無需直接參與複雜的證明生成過程。Demox Labs、Monadicus、Supranational、Puzzle等生態夥伴在這一領域發揮了重要作用，通過提供高級自動化測試工具，促進了驗證者和委託者之間的高效協作，同時也提高了網路的穩定性和驗證效率。
                                    最後，Aleo在PoSW機制的實施、代幣經濟模型的優化、核心協定的升級以及驗證者委託機制的推進上均取得了顯著進展。這些進展不僅鞏固了Aleo在隱私計算領域的技術創新地位，也為即將到來的主網上線奠定了堅實基礎。隨著測試網的不斷反覆運算和社區的積極參與，Aleo正一步步向構建一個私有、無需許可、且可程式設計的區塊鏈網路邁進，為使用者和開發者提供前所未有的隱私保護和創新空間。讓我們期待主網的早日到來。
                                    </p>
                                    </div>
                            </div>
                        </div>
                    </div>
                     <div class="pattern-bg">
                        <img src="@/assets/images/dot-round-bg.png" class="patter" alt="Patter">
                        <img src="@/assets/images/dot-round-bg.png" class="patter patter-2" alt="Patter">
                     </div>
                </section>
            </main>
        
    </template>
    <script>
    export default {
        name: 'About',
        data() {
            return {
                msg: 'Welcome to Your Vue.js App',
            }
        },
    }
    </script>