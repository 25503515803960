import site_en from "./module/site_en"
const en = {
    header: {
        switchLanguage: "Switch Language",
        language: {
            zh: "zh",
            zhName: "中文",
            en: "en",
            enName: "English"
        },
        menu: {
            home: "Home",
            pool: "Prover",
            about: "About",
        },
    },
    footer: {
        "title":"2022 Cryptorius all Rights Reserved. Designed By ZKLion"
    },
    home: {
        banner: {
            title1: 'The most trusted',
            title2: 'Zero Knowledge Proof',
            title3:'mining provider',
            info: 'Optimization of the zkp calculation and Zk-snarks algorithm',
        },
        cryptoriusFeature: {
            label: "",
            title: 'Supported Networks',
            item1: {
                title: 'Aleo',
                info:'Leading Aleo mining solution provider'
            },
            item2: {
                title: 'Filecoin',
                info:'Leading Filecoin mining provider '
            },
            item3: {
                title: 'Aleo',
                info:'Scroll is a security-focused scaling solution for Ethereum '
            }
        },
        advantage: { 
            label: 'Advantage',
            title: 'WHY CHOOSE US',
             item1: {
                title: 'High optimization mining software',
                info:'The zero knowledge proof calculation and Zk-snarks algorithm are optimized'
            },
            item2: {
                title: 'Full stack managed service',
                info:'7*24*365 operation and maintenance with high standard mining server'
            },
            item3: {
                title: 'ASIC',
                info:'High performance and low power consumption ASIC computer chip for zkp'
            },
            item4: {
                title: 'Monitoring system',
                info:'Self-developed monitoring and analysis system, data collection, visualization, monitoring alarm, data analysis in one '
            } 
        },
        news: {
            label: 'NEWS',
            title: 'Our Blog',
            info: "We provide a series of full stack services, including ZKP algorithm acceleration, SaaS solution, network node operation and maintenance, data monitoring and analysis, and other services.",
            item0: {
                title: 'Overview of Aleo project progress and community updates: Significant adjustments to token rewards in the first year.',
                date: 'Apr,2024',
                auther:'by ZKLion.com',
                info:"Recently, several significant developments surrounding Aleo, particularly the advantages demonstrated by its Proof of Succinct Work (PoSW) mechanism in the first year, as well as optimizations to its token economics model, core protocol updates, and zkSnark validator delegation mechanism, have underscored Aleo's leading position in the privacy computing race."
            },
            item1: {
                title: 'The benefits of zero-knowledge for mechanism design: Increasing trust, credibility, and transparency',
                date: 'SEP, 2023',
                auther:'by Aleo.org',
                info:"Since software started eating the world more than a decade ago, the scale of available information has never been greater. As a result, developers now have the opportunity to prove quite a lot of things using verifiable computing, creating succinct proofs about almost all of the world's interactions and software. However, since the possibilities are so great, we need to focus on the most practical applications, where privacy can make the biggest impact."
            },
            item2: {
                title: 'Cloud Storage Services: Understanding the Decentralized Storage Approach',
                date: 'Jan, 2023',
                auther:'by filecoin.io',
                info:'In this post we take a look at how a decentralized approach to cloud storage is setting a new standard for the industry. We examine why decentralized cloud storage is needed, how it works, and its defining characteristics.'
            },
            item3: {
                title: 'Zero-Knowledge Primitives by Aleo: Forging a Private Web3 Landscape',
                date: 'SEP, 2023',
                auther:'by Medium.com',
                info:'Aleo is a groundbreaking project focused on advancing privacy and security within the Web3 ecosystem. It leverages cutting-edge zero-knowledge cryptography to enable the creation of fully private and scalable decentralized applications (dApps).'
            },
            item4: {
                title: "Announcing the 'Contribute to Scroll' Initiative",
                date: 'July,2023 ',
                auther:'by  scroll.io ',
                info:'At Scroll, we value and appreciate the efforts of those who contribute to our ecosystem. Thanks to our friends at GitPOAP, we are able to acknowledge and reward contributors.'
            } 
        },
        contact: {
            label: "CONTACT",
            title: "Get In Touch",
            telegram: 'Join us on Telegram',
            name: "Your Name*",
            email: "Your Email*",
            message:"Your Message*",
            info:"We will reply positively, please pay attention to check the email."
        }
    },
    about: {
        title: 'About Us',
        info:'zklion is an enterprise focused on emerging technology fields such as blockchain, Aleo, and GameFI. Committed to providing efficient, safe, and trustworthy solutions and services for enterprises and individuals. Through blockchain technology, zklion has created a decentralized digital ecosystem for customers, achieving information transparency, traceability, and non tampering. In the Aleo field, zklion utilizes privacy protection and encryption technology to provide users with privacy security guarantees for decentralized applications. At the same time, zklion is also committed to research and development in the GameFI field, integrating blockchain with gaming, promoting the development of the gaming economy and protecting user rights. As a leader in the industry, zklion continues to innovate and explore new paths for promoting the development and application of blockchain technology.'
    },
    btn: {
        startMining: "Start Mining",
        comingSoon: 'Coming Soon',
        readMore: 'Read More',
        sendMessage:'Send Message'
    },
    ...site_en
}

export default en