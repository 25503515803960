<template>
    <header class="header">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-8 d-flex flex-center">
                    <div class="logo">
                        <a href="/"><img src="@/assets/images/logo.png" style="height: 38px;" alt="Cryptorius Logo"></a>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-8 col-4">
                    <div class="menu-toggle">
                        <span></span>
                    </div>
                    <div class="main-menu">
                        <div class="nav-menu text-right">
                            <ul>
                                <li v-bind:class="{ 'active': routerName == '/' }" ><a href="/">{{ $t('header.menu.home') }}</a></li>
                                <li><a href="http://pool.zklion.com/">{{$t('header.menu.pool')}}</a></li>
                                <li v-bind:class="{ 'active': routerName == '/about'}" ><a href="/about">{{ $t('header.menu.about') }}</a></li>
                            </ul>
                        </div>
                        <span style="position: relative;">
                            <div class="btn-group">
                            <span  class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style="color:#fff;font-size:14px;font-weight:500">
                                {{ $i18n.locale == 'en' ? 'English' : "中文" }}
                            </span>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="javascript:void(0)"  @click="changeLang('zh')">中文</a></li>
                                <li><a class="dropdown-item" href="javascript:void(0)"  @click="changeLang('en')">English</a></li>
                            </ul>
                            </div>
                        </span>
                    </div>
                   
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    name: 'Header',
    data() {
        return {
            routerName: window.location.pathname
        }
    },
    mounted() { 
        // console.log(this.$router)
        // console.log(this.$router.currentRoute)
        // console.log(this.$router.currentRoute.value.name)
        // console.log(window.location.pathname)
        // console.log(this.router.currentRoute)
    },
    methods: {
        changeLang(lang) {
            this.$i18n.locale = lang;
            this.$store.commit("switchLang", lang);
            this.lang_selected = lang;
            location.reload();
            // locale.value = lang
            // localStorage.setItem('lang', lang)// 重要！下面遇到问题里解释
        }
    }
}
</script>