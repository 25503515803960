import request from '@/utils/request'
import { baseApi } from '@/api/apilist'

export function saveMessage(data) {
  return request({
    url: `${baseApi}/website/contact/save`,
    method: 'post',
    data,
    headers: {
      returnAll: true
    }
  })
}
 // 国内IP拦截 
/* sh 的接口  IP 读取是否是中国--- */
// http://172.30.56.104:5210/api/v1/aleo/ip/forbidden
export function checkChinaMainland() {
    return request({
        url: `${baseApi}/aleo/ip/forbidden`,
        method: 'get',
        headers: {
            returnAll: true
        }
    })
}