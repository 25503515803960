const site_zh = {
    homePage: {
        title: 'ZKLion | 領先的ZKP技術解決方案和基礎設施服務商',
        keywords: 'ZKLion,zkp,零知識證明技術,區塊鏈技術,區塊鏈應用,智能合約,隱私計算,去中心化服務,算力服務,網絡節點優化',
        description: 'ZKLion是領先的零知識證明技術解決方案和基礎設施服務的提供商，提供全棧服務，包括ZKP算法加速、SaaS解決方案、網絡節點運維、數據監控與分析等，致力於促進智能合約、隱私計算和去中心化的ZK證明層的發展。'
    },
    aboutPage: {
        title: '關於我們 | ZKLion | 領先的ZKP技術解決方案和基礎設施服務商',
        keywords: 'ZKLion,zkp,零知識證明技術,區塊鏈技術,區塊鏈應用,智能合約,隱私計算,去中心化服務,算力服務,網絡節點優化',
        description: 'ZKLion是領先的零知識證明技術解決方案和基礎設施服務的提供商，提供全棧服務，包括ZKP算法加速、SaaS解決方案、網絡節點運維、數據監控與分析等，致力於促進智能合約、隱私計算和去中心化的ZK證明層的發展。'
    }
}

export default site_zh;