import router from './router'

router.beforeEach(async (to, from, next) => {
    // 给界面+标题
    if (to.meta.title) {
        document.title = to.meta.title;
        if (document.querySelector('meta[name="keywords"]')) {
            document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords)
        }
        if (document.querySelector('meta[name="description"]')) {
            document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
        }
    }
    next()
})