<template>
    <main class="main">
        <!-- banner -->
        <section class="home-banner relative overflow-h">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 d-flex flex-center">
                        <div class="banner-content relative z-index-1">
                            <h1 class="banner-title wow fadeInLeft">The most trusted <br><span> Zero Knowledge Proof </span> mining provider</h1>
                            <p class="wow fadeInLeft">{{ $t('home.banner.info') }}</p>
                            <a href="https://pool.zklion.com/" class="button wow fadeInLeft">{{ $t('btn.startMining') }}
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInUp">
                        <div class="banner-img">
                            <img src="@/assets/images/banner-img.png" alt="Banner Image">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- product -->
        <section class="features bluish-purple ptb-100 overflow-h">
            <div class="container relative">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 relative z-index-1">
                        <div class="section-heading text-center">
                            <h6 class="sub-title text-uppercase wow fadeInUp">{{ $t('home.cryptoriusFeature.label') }}</h6>
                            <h2 class="title wow fadeInUp">{{ $t('home.cryptoriusFeature.title') }}</h2>
                        </div>
                    </div>
                </div>
                <div class="row relative z-index-1">
                    <div class="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div class="box-outer mb-30 transition">
                            <div class="box-inner purple frontend">
                                <img src="@/assets/images/aleo.info.png" class="feature-icon mb-30" alt="Aleo">
                                <h4 class="feature-title mb-20">
                                    <a href="#">{{ $t('home.cryptoriusFeature.item1.title') }}</a>
                                </h4>
                                <p class="box-inner-text">
                                    {{ $t('home.cryptoriusFeature.item1.info') }}
                                </p>
                                <!-- <button type="button" class="btn btn-secondary">
                                Start Mining
                            </button> -->
                            </div>
                            <div class="box-inner purple backend" >
                                <div style="width: 100%;">
                                    <p v-if="$i18n.locale == 'en'" class="text-show">
                                        Aleo is a Layer 1 blockchain bringing privacy to the web.<br><br>
                                        zklion development team has made excellent optimization of the zero-knowledge proof algorithm (Zk-snarks),
                                        which has enabled us to perform strongly in Aleo Testnet.
                                    </p>
                                    <p v-else>
                                         Aleo是一個為網絡帶來隱私的第1層區塊鏈.<br><br>
                                        zklion開發團隊對零知識證明算法（Zk-snarks）進行了出色的優化，使我們能夠在Aleo Testnet中表現出色。
                                    </p>

                                    <p style="width: 100%; text-align: center;">
                                        <a href="https://pool.zklion.com/" target="_blank">
                                            <button class="button">{{ $t('btn.startMining') }}</button>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div class="box-outer mb-30 transition">
                            <div class="box-inner purple frontend">
                                <img src="@/assets/images/filecoin012.png" class="feature-icon mb-30" alt="Aleo">
                                <h4 class="feature-title mb-20">
                                    <a href="#">{{ $t('home.cryptoriusFeature.item2.title') }}</a>
                                </h4>
                                <p class="box-inner-text">
                                   {{ $t('home.cryptoriusFeature.item2.info') }}
                                </p>
                            </div>
                            <div class="box-inner purple backend">
                                    <div style="width: 100%;">
                                        <p  v-if="$i18n.locale == 'en'"  class="text-show" >
                                            Filecoin is a peer-to-peer network that allows anyone to store and retrieve data on the internet. Built-in economic incentives ensure that files are stored and retrieved reliably and continuously for however long a user specifies.
                                            <br><br>
                                            As one of the top 10 head miners during the Space Race, zklion team has been committed to providing a stable sector sealing.
                                        </p>
                                        <p v-else>
                                            Filecoin是一個對允許任何人在互聯網上存儲和檢索數據的點對點網絡。內置的經濟激勵措施可確保無論用戶指定多長時間，都能可靠、連續地存儲和檢索文件。<br><br>作為太空競賽期間排名前十的礦工之一，zklion團隊一直致力於提供穩定的扇區密封。
                                        </p>
                                        <p style="width: 100%; text-align: center;">
                                            <button type="button" class="btn btn-secondary" disabled>{{ $t('btn.comingSoon') }}</button>
                                        </p>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div class="box-outer mb-30 transition">
                            <div class="box-inner purple frontend">
                                <img src="@/assets/images/scroll_4.jpg" class="feature-icon mb-30" alt="Aleo">
                                <h4 class="feature-title mb-20">
                                    <a href="#">{{ $t('home.cryptoriusFeature.item3.title') }}</a>
                                </h4>
                                <p class="box-inner-text">
                                   {{ $t('home.cryptoriusFeature.item3.info') }}
                                </p>
                            </div>
                            <div class="box-inner purple backend">
                               <div style="width: 100%;">
                                    <p  v-if="$i18n.locale == 'en'"  class="text-show">
                                    Scroll use innovations in scaling design and zero knowledge proofs to build a new layer on Ethereum.<br><br>
    The team, in open-source collaboration with others in the Ethereum community, has created a “zkEVM” that allows for all activity on the network, which behaves just like Ethereum, to be secured by smart contracts on Ethereum.
                                    </p>
                                    <p v-else>
                                        Scroll在擴展設計和零知識證明方面進行了創新，在以太坊上構建了一個新層級。
                                        <br><br>
                                        該團隊與以太坊社區的其他人進行了開源合作，創建了一個「zkEVM」，允許網絡上的所有活動像以太坊一樣，被以太坊上的智能合約保護。
                                    </p>
                                    <p class="btn-show" style="width: 100%; text-align: center;">
                                        <button type="button" class="btn btn-secondary" disabled>{{ $t('btn.comingSoon') }}</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
                <div class="pattern-bg">
                    <img src="@/assets/images/dotline-bg.png" class="patter" alt="Patter">
                    <img src="@/assets/images/dotline-bg.png" class="patter patter-2" alt="Patter">
                </div>
            </div>
        </section>
        <!-- ADVANTAGE -- Why choose us -->
        <section class="works relative purple ptb-100 overflow-h">
            <div class="container">
                <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="section-heading text-center">
                        <h6 class="sub-title text-uppercase wow fadeInUp">{{ $t('home.advantage.label') }}</h6>
                        <h2 class="title wow fadeInUp">{{ $t('home.advantage.title') }}</h2>
                        <!-- <p class="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p> -->
                    </div>
                </div>
                </div>
                <div class="row relative purple z-index-1">
                    <div v-show="isShow" class="relative z-50 hidden h-0 w-full items-center justify-center [mix-blend-mode:screen] lg:flex">
                        <video id="videoPlayer" ref="videoPlayer" loop autoplay muted playsinline="true" class="absolute [mix-blend-mode:screen]">
                            <source src="https://static.zklion.com/media/polygon.mp4" type="video/mp4">
                            <p>I'm sorry; your browser doesn't support HTML5 video.</p>
                        </video>
                    </div>
                    <div class="col-xl-3 col-xl-3 col-md-3 mp-60 feature-box wow fadeInUp" style="background: '@/assets/images/earth.jpeg'">
                        <div class="mb-30 transition">
                            <div class="box-inner purple frontend">
                                <i class="far fa-layer-plus" />
                                <img src="@/assets/images/ADVANTAGE-1.png" class="advantage-icon mb-30" alt="Aleo">
                                <h4 class="feature-title mb-20">
                                    <a href="#">{{ $t('home.advantage.item1.title') }}</a>
                                </h4>
                                <p>
                                    {{ $t('home.advantage.item1.info') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xl-6 col-md-6 feature-box wow fadeInUp">
                    </div>
                    <div class="col-xl-3 col-xl-3 col-md-3 mp-60 feature-box wow fadeInUp">
                        <div class="mb-30 transition">
                            <div class="box-inner purple frontend">
                                <img src="@/assets/images/ADVANTAGE-2.png" class="advantage-icon mb-30" alt="Aleo">
                                <h4 class="feature-title mb-20">
                                    <a href="#">{{ $t('home.advantage.item2.title') }}</a>
                                </h4>
                                <p>
                                   {{ $t('home.advantage.item2.info') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xl-3 col-md-3 mp-60  feature-box wow fadeInUp">
                            <div class="mb-30 transition">
                                <div class="box-inner purple frontend">
                                    <img src="@/assets/images/ADVANTAGE-3.png" class="advantage-icon mb-30" alt="Aleo">
                                    <h4 class="feature-title mb-20">
                                        <a href="#">{{ $t('home.advantage.item3.title') }}</a>
                                    </h4>
                                    <p>
                                       {{ $t('home.advantage.item3.info') }}
                                    </p>
                                </div>
                            </div>
                    </div>
                    <div class="col-xl-6 col-xl-6 col-md-6 feature-box wow fadeInUp">
                    </div>
                    <div class="col-xl-3 col-xl-3 mp-60 col-md-3 feature-box wow fadeInUp">
                        <div class="mb-30 transition">
                            <div class="box-inner purple frontend">
                                <img src="@/assets/images/ADVANTAGE-4.png" class="advantage-icon mb-30" alt="Aleo">
                                <h4 class="feature-title mb-20">
                                    <a href="#">{{ $t('home.advantage.item4.title') }}</a>
                                </h4>
                                <p>
                                   {{ $t('home.advantage.item4.info') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pattern-bg">
                <img src="@/assets/images/dot-round-bg.png" class="patter" alt="Patter">
                <img src="@/assets/images/dot-round-bg.png" class="patter patter-2" alt="Patter">
            </div>
        </section>
        <!-- blog -->

        <section class="our-blog bluish-purple relative overflow-h ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 relative z-index-1">
                        <div class="section-heading text-center">
                            <h6 class="sub-title text-uppercase wow fadeInUp">{{$t('home.news.label')}}</h6>
                            <h2 class="title wow fadeInUp">{{ $t('home.news.title') }}</h2>
                            <p class="wow fadeInUp">{{ $t('home.news.info') }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 relative z-index-1 wow fadeInUp">
                        <div class="blog-slider owl-carousel">
                            <div class="blog-boxs purple">
                                    <a href="" class="blog-img">
                                        <img src="@/assets/images/blog-000.jpg" alt=""></a>
                                    <div class="blog-content">
                                        <span class="blog-date">{{ $t('home.news.item0.date') }} 
                                            <span class="blog-authore">{{ $t('home.news.item0.auther') }}</span></span>
                                        <h5 class="blog-title">
                                            <a href="">{{ $t('home.news.item0.title') }}</a></h5>
                                        <p>{{ $t('home.news.item0.info') }}</p>
                                        <a href="/blog" class="read-more text-uppercase">{{ $t('btn.readMore') }} <i
                                            class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            <div class="blog-boxs purple">
                                    <a href="" class="blog-img">
                                        <img src="@/assets/images/blog-001.jpg" alt=""></a>
                                    <div class="blog-content">
                                        <span class="blog-date">{{ $t('home.news.item1.date') }} <span class="blog-authore">{{ $t('home.news.item1.auther') }}</span></span>
                                        <h5 class="blog-title">
                                            <a href="">{{ $t('home.news.item1.title') }}</a></h5>
                                        <p>{{ $t('home.news.item1.info') }}</p>
                                        <a href="" class="read-more text-uppercase">{{ $t('btn.readMore') }} <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                          <div class="blog-boxs purple">
                                        <a href="" class="blog-img">
                                            <img src="@/assets/images/blog-002.jpg" alt=""></a>
                                        <div class="blog-content">
                                            <span class="blog-date">{{ $t('home.news.item2.date') }} <span class="blog-authore">{{ $t('home.news.item2.auther') }}</span></span>
                                            <h5 class="blog-title">
                                                <a href="">{{ $t('home.news.item2.title') }}</a></h5>
                                            <p>{{ $t('home.news.item2.info') }}</p>
                                            <a href="" class="read-more text-uppercase">{{ $t('btn.readMore') }}  <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                              <div class="blog-boxs purple">
                                        <a href="" class="blog-img">
                                            <img src="@/assets/images/blog-003.jpg" alt=""></a>
                                        <div class="blog-content">
                                            <span class="blog-date">{{ $t('home.news.item3.date') }} <span class="blog-authore">{{ $t('home.news.item3.auther') }}</span></span>
                                            <h5 class="blog-title">
                                                <a href="">{{ $t('home.news.item3.title') }}</a></h5>
                                            <p>{{ $t('home.news.item3.info') }}</p>
                                            <a href="" class="read-more text-uppercase">{{ $t('btn.readMore') }}  <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                              <div class="blog-boxs purple">
                                        <a href="" class="blog-img">
                                            <img src="@/assets/images/blog-004.jpg" alt=""></a>
                                        <div class="blog-content">
                                            <span class="blog-date">{{ $t('home.news.item4.date') }} <span class="blog-authore">{{ $t('home.news.item4.auther') }}</span></span>
                                            <h5 class="blog-title">
                                                <a href="">{{ $t('home.news.item4.title') }}</a></h5>
                                            <p>{{ $t('home.news.item4.info') }}</p>
                                            <a href="" class="read-more text-uppercase">{{ $t('btn.readMore') }}  <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pattern-bg">
                <img src="@/assets/images/blog-bg-top.png" class="patter" alt="Patter">
                <img src="@/assets/images/blog-bg-bottom.png" class="patter patter-2" alt="Patter">
            </div>
        </section>
    </main>
</template>

<script>

export default {
    name: 'Index',
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            isShow: true,

        }
    },
    beforeCreate() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            this.isShow = false;
            // 执行相应代码或直接跳转到手机页面
        } else {
            this.isShow = true;
        }
    },
    mounted() { 
        // this.$refs.videoPlayer.player.play();
        this.$refs.videoPlayer.load();
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.feature-box{
    min-height: 288px;
}

.mobile .works .row >.items-center{
    display: none;
}
.mobile .works .row .col-xl-6{
    display: none;
}
.mobile .works .row .mp-60{margin-top: 0px;}
</style>