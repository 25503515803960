<template>
<!-- <p>
    zklion is an enterprise focused on emerging technology fields such as blockchain, Aleo, and GameFI. Committed to providing efficient, safe, and trustworthy solutions and services for enterprises and individuals. Through blockchain technology, zklion has created a decentralized digital ecosystem for customers, achieving information transparency, traceability, and non tampering. In the Aleo field, zklion utilizes privacy protection and encryption technology to provide users with privacy security guarantees for decentralized applications. At the same time, zklion is also committed to research and development in the GameFI field, integrating blockchain with gaming, promoting the development of the gaming economy and protecting user rights. As a leader in the industry, zklion continues to innovate and explore new paths for promoting the development and application of blockchain technology.
</p> -->
      <main class="main">
            <section class="page-banner text-center relative" style="display: none;">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12">
                            <h1 class="page-title wow fadeInUp">About us</h1>
                            <ul class="breadcum wow fadeInUp">
                                <li><a href="index.html">Home</a></li>
                                <li>Pages</li>
                                <li>About us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="our-mission works purple ptb-100 relative overflow-h">
                <div class="container">
                    <div class="row flex-center">
                        <div class="col-xl-6 col-lg-6 col-md-12 text-center">
                            <div class="mission-video wow fadeInLeft">
                                <img src="@/assets/images/work-img.png" alt="Mission">
                               
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInRight">
                            <div class="mission-content">
                                <h3 class="mission-title">{{$t('about.title')}}</h3>
                                <p>{{ $t('about.info') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="pattern-bg">
                    <img src="@/assets/images/dot-round-bg.png" class="patter" alt="Patter">
                    <img src="@/assets/images/dot-round-bg.png" class="patter patter-2" alt="Patter">
                 </div>
            </section>
        </main>
    
</template>
<script>
export default {
    name: 'About',
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
        }
    },
}
</script>