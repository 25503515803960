
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n/i18n.js'
import router from './router'
import store from './store'
import './permission' // permission control

// createApp(App).mount('#app')
const app = createApp(App)
// const app = createApp(App).use(store).use(router)
app.use(store);
app.use(router);
app.use(i18n);

app.mount("#app");